.filters-bar{
    width: 100%;
    display: flex;
    text-align: center;
    .select-button{
        width: 100%;    
        padding: .75rem  1.25rem;
        border: 1px solid lightgrey;
        p{
        margin: 0;
        }
        span{
        font-size: .85rem;
        opacity: 50%;
        }
    }
    .select-button:hover{
        background-color: lightgrey;
        cursor: pointer;
        transition: .15s linear;
    }
}

ion-popover::part(content){
    width: 250px;
}