// .fc .fc-timeline-lane-frame{
//     height: 34px;
// }
// .fc .fc-event{
//     height: 34px;
// }



.fc-button {
    box-shadow: none !important;
    padding: 0 0.5rem !important;
    display: inline-block;
    font-weight: 400;
    text-align: center;
    vertical-align: middle;
    -webkit-user-select: none;
    -moz-user-select: none;
    user-select: none;
    background-color: transparent;
    border: 1px solid transparent;
    font-size: 1em;
    line-height: 1.5;
    border-radius: 0.25em;
    &.left {
        border-right: 0;
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
    }
    &.middle {
        border-radius: 0;
    }
    &.right {
        border-left: 0;
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
    }
    &.active {
        color: white !important;
        border-color: var(--ion-color-primary-shade) !important;
        background: var(--ion-color-primary) !important;
    }
}

.fc-button-primary {
    background: white !important;
    border-color: var(--ion-color-primary) !important;
    color: var(--ion-color-primary) !important;
}

.fc-button-primary:not(:disabled).fc-button-active,
.fc-button-primary:not(:disabled):active {
    color: white !important;
    border-color: var(--ion-color-primary-shade) !important;
    background: var(--ion-color-primary) !important;
}

.custom-fc-title, .fc .fc-toolbar-title {
    font-size: 1.5rem !important;
    @media screen and (max-width: 580px) {
        font-size: 0.8rem !important;
    }
}
.fc-toolbar-chunk{
    display: flex;
}

