.loading-spinner {
    padding: 1rem;
    text-align: center;
}

.dateButton {
    box-shadow:
        rgb(0 0 0 / 20%) 0px 3px 1px -2px,
        rgb(0 0 0 / 14%) 0px 2px 2px 0px,
        rgb(0 0 0 / 12%) 0px 1px 5px 0px;
}

.roundButton {
    color: black;
    text-align: center;
    margin: 0px;
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-flex-align: center;
    -ms-flex-align: center;
    -webkit-align-items: center;
    align-items: center;
    justify-content: center;

    ion-button {
        margin-top: 0;
        margin-bottom: 0;
    }
    &.input {
        text-align: center;
        color: black;
    }
    ::input {
        text-align: center;
    }
    ::placeholder {
        text-align: center;
        color: black;
    }
    ::-ms-input-placeholder {
        /* Microsoft Edge */
        text-align: center;
        color: black;
    }
}
.not_today {
    color: gray;
}

.action-sheet-disabled-btn {
    pointer-events: none;
    opacity: 0.4;
}

.outline-badge {
    background: transparent;
    color: black;
    font-weight: normal;
    border: 1px solid black;
    margin-right: 0.3rem;
    margin-top: 0.3rem;
    white-space: break-spaces;
    &.gray{
        border: 1px solid var(--ion-color-lightGray);
        color: var(--ion-color-lightGray);
    }
}
