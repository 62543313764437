/*
 * App Global CSS
 * ----------------------------------------------------------------------------
 * Put style rules here that you want to apply globally. These styles are for
 * the entire app and not just one component. Additionally, this file can be
 * used as an entry point to import other CSS/Sass files to be included in the
 * output CSS.
 * For more information on global stylesheets, visit the documentation:
 * https://ionicframework.com/docs/layout/global-stylesheets
 */

/* Core CSS required for Ionic components to work properly */
@import "~@ionic/angular/css/core.css";

/* Basic CSS for apps built with Ionic */
@import "~@ionic/angular/css/normalize.css";
@import "~@ionic/angular/css/structure.css";
@import "~@ionic/angular/css/typography.css";
@import "~@ionic/angular/css/display.css";

/* Optional CSS utils that can be commented out */
@import "~@ionic/angular/css/padding.css";
@import "~@ionic/angular/css/float-elements.css";
@import "~@ionic/angular/css/text-alignment.css";
@import "~@ionic/angular/css/text-transformation.css";
@import "~@ionic/angular/css/flex-utils.css";
@import "@angular/material/prebuilt-themes/deeppurple-amber.css";

/* Custom SCSS */
@import "assets/scss/_page.scss";
@import "assets/scss/_paddings.scss";
@import "assets/scss/_margins.scss";
@import "assets/scss/_align.scss";
@import "assets/scss/custom/_filters-bar.scss";
@import "assets/scss/custom/_buttons.scss";
@import "assets/scss/custom/_ionic_components.scss";
@import "assets/scss/custom/_texts.scss";
@import "assets/scss/custom/_fullcalendar.scss";
@import "assets/scss/custom/_alerts.scss";
@import "assets/scss/custom/_screen-sizes.scss";

/*Emojis*/
@import "@ctrl/ngx-emoji-mart/picker";

/* Packages CSS */

.custom-image-gallery-modal::part(content) {
  width: 90%;
  height: 90%;
}
.awards-modal::part(content) {
  width: 100%;
  height: 100%;
}

.full-width-modal::part(content) {
  width: 100%;
  height: 100%;
}

.pinModal::part(backdrop) {
  background-color: white;
  opacity: 1 !important;
}

.u-wrapper{
  max-width: 1280px;
  margin: 0 auto;
  padding-left: 1.5rem;
  padding-right: 1.5rem;
  @media screen and (max-width:425px) {
      padding: .75rem;
  }
  @media screen and (min-width: 768px){
      padding-left: 3rem;
      padding-right: 3rem;
  }
}

.g-1 {
  gap: 1rem
}

.pinModal::part(content) {
  --border-radius: 2px;
  --box-shadow: 0 28px 48px rgba(0, 0, 0, 0.4);
}
.barcodeScanner::part(content) {
  background-color: transparent;
}

.videoFrame {
  width: 100%;
  height: 100%;
  position: absolute;
  border: 4px solid var(--ion-color-primary);
}
.videoFrame::part(backdrop) {
  display: none;
}
.videoFrame::part(content) {
  width: 100%;
  height: 100%;
  --background: black;
  background-color: black;
}

.chatFrame {
  position: absolute;
  width: 40%;
  max-height: 500px;
  border: 4px solid var(--ion-color-primary);
  left: 59%;
  top: 39%;
  z-index: 30000 !important;
}
.chatFrame::part(backdrop) {
  display: none;
}
.chatFrame::part(content) {
  width: 100%;
  height: 100%;
  --background: black;
  background-color: black;
}

.chatFrameMobile {
  position: absolute;
  width: 90%;
  max-height: 700px;
  border: 4px solid var(--ion-color-primary);
  left: 24px;
  top: 110px;
  bottom: 70px;
  z-index: 30000 !important;
}
.chatFrameMobile::part(backdrop) {
  display: none;
}
.chatFrameMobile::part(content) {
  width: 100%;
  height: 100%;
  --background: black;
  background-color: black;
}

.text-uppercase {
  text-transform: uppercase;
}

.action-sheets-chats-list {
  .starIcon {
    ion-icon {
      color: var(--ion-color-gold);
    }
  }
  .primary {
    ion-icon {
      color: var(--ion-color-primary);
    }
  }
  .secondary {
    ion-icon {
      color: var(--ion-color-secondary);
    }
  }
}

.flex-column-center {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.display-around {
  display: flex;
  justify-content: space-around;
}

.display-sp-btw {
  display: flex;
  justify-content: space-between;
}


.justify-center {
  display: flex;
  justify-content: center;
}

.my-custom-modal-css::part(content) {
  height: 100%;
  position: absolute;
  display: block;
}

ion-item {
  &.recording {
    opacity: 1 !important;
  }
}

ion-textarea {
  &.recording {
    --placeholder-opacity: 1 !important;
  }
}

.sc-ion-textarea-md {
  overflow: auto !important;
}
.sc-ion-textarea-ios {
  overflow: auto !important;
}

.pulse-effect {
  animation: blinker 1.5s linear infinite;
}

@keyframes blinker {
  50% {
    opacity: 0;
  }
}

.alert-checkbox-label {
  white-space: normal !important;
}

.alert-checkbox-label.sc-ion-alert-ios {
  padding-left: unset;
  padding-right: unset;
  -webkit-padding-start: 0;
  padding-inline-start: 0;
  -webkit-padding-end: 0;
  padding-inline-end: 0;
}

.alert-tappable.sc-ion-alert-ios {
  height: 3rem;
}

.alert-wrapper {
  max-width: unset !important;
}

ion-select::part(text) {
  white-space: normal !important;
}

// formly
formly-field {
  input {
    opacity: 1 !important;
    width: 100% !important;
    color: black;
  }
  select {
    opacity: 1 !important;
    max-width: 100%;
    width: 100% !important;
    color: black;
  }
  textarea {
    opacity: 1 !important;
    width: 100% !important;
    color: black;
  }
}

formly-wrapper-form-field {
  .form-label {
    font-weight: bold;
    opacity: 1 !important;
    color: black;
  }
}

.domus-textarea {
  width: 90%;
  border: 1px solid;
  border-radius: 10px;
  margin: 1rem;
  padding: 5px 10px;
}

.pagination {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  margin-top: 1rem;
  &.top {
    margin-top: 0rem;
  }
}

.flex-center {
  display: flex;
  justify-content: center;
}

.pagination a {
  color: black;
  float: left;
  padding: 8px 16px;
  text-decoration: none;
}

.pagination select {
  color: black;
  float: right;
  margin-left: auto;
}

.pagination ion-icon {
  float: left;
  margin-right: auto;
}

.pagination a.active {
  background-color: var(--ion-color-primary);
  color: white;
  border-radius: 5px;
}

.pagination a:hover:not(.active) {
  background-color: var(--ion-color-lightGray);
  border-radius: 5px;
}

.p-0 {
  padding: 0 !important;
}

.pointer {
  cursor: pointer;
}

//scroll:
::-webkit-scrollbar {
  display: unset !important;
  width: 12px;
  height: 12px;
  background-color: transparent;
}
::-webkit-scrollbar-thumb {
  box-shadow: inset 0 0 12px 12px lightgray;
  border: solid 3px transparent;
  border-radius: 8px;
}
::-webkit-scrollbar-track {
  background-color: transparent;
}
//pels components que tenen el ion-content::part(scroll) { overflow-y: hidden; }
.custom-scroll {
  overflow: auto;
  height: 100%;
}
//TODO: treure quan arreglin bug ionic: https://github.com/ionic-team/ionic-framework/issues/25384
ion-item {
  pointer-events: auto;
  &.item-disabled {
    pointer-events: none !important;
  }
}

ion-modal {
  --border-radius: 4px;

  &.custom-modal-small {
    --width: 320px;
    --height: 250px;
  }
  &.custom-modal-mid {
    --width: 320px;
    --height: 320px;
  }
  &.custom-modal-auto {
    --width: fit-content;
    --height: fit-content;
    --border-radius: 10px;
  }
  &.repeat-modal {
    --width: fit-content;
    --height: auto;
  }
}

ion-breadcrumbs {
  cursor: pointer;
}

ion-popover.reaction-icons-popover-global-scss {
  &::part(content) {
    border-radius: 20px;
    min-width: fit-content;
  }
}

ion-popover.round {
  &::part(content) {
    border-radius: 20px;
  }
}

ion-popover.no-background {
  &::part(content) {
    --background: transparent;
    box-shadow: none;
  }
}

.borderLoading {
  .loading-wrapper {
    border-radius: 10px;
  }
}

.mw-90 {
  max-width: 90%;
}

.mw-100 {
  max-width: 100%;
}

.w-50 {
  width: 50%;
}

td.fc-day-sat,
td.fc-day-sun {
  background-color: whitesmoke;
}

#workday-register-component {
  width: 260px !important;
  &.minimized {
    width: 46px !important;
  }
}

.o-visible {
  overflow: visible;
}

ion-popover.round {
  --backdrop-opacity: 0;
  &::part(content) {
    border-radius: 10px;
    
  }
}

ion-popover.no-background {
  --backdrop-opacity: 0;
  &::part(content) {
    --background: transparent;
    box-shadow: none;
  }
}

#app-banner {
  --grey: rgba(233, 233, 233);
  --grey-text: rgba(141, 141, 141);
  --grey-icon: rgba(194, 194, 194);

  transition: all 1s;
  height: 90px;
  display: flex;
  align-items: center;
  background: white;
  border-bottom: 1px solid var(--grey);
  gap: 0.5em;
  padding: 0 .5em;

  &.display {
    display: flex!important;
  }

  ion-icon {
    color: var(--grey-icon);
  }

  .logo {
    border-radius: 10px;
    border: 1px solid var(--grey);
    height: 60px;
    width: 60px;
    display: flex;
    align-items: center;
    justify-content: center;

    img {
      width: 70%;
      height: 70%;
      object-fit: cover;
    }
  }

  .text {
    flex: 1;
    display: flex;
    flex-direction: column;
    gap: 0.1em;

    p:first-of-type {
      margin: 0;
      font-weight: 500;
    }

    p:last-of-type {
      margin: 0;
      color: var(--grey-text);
      font-size: 14px;
    }
  }

  button {
    background: #007cff;
    color: white;
    font-weight: bold;
    padding: 0.5em 1em;
    border-radius: 15px;
    font-size: 15px;
  }
}