.alert-box {
    padding: 0.75rem;
    &.warning {
        background: var(--ion-color-accent300) !important;
        h6 {
            color: var(--ion-color-accent800) !important;
        }
    }
    &.info {
        background: var(--ion-color-neutro200) !important;
        h6 {
            color: var(--ion-color-neutro500) !important;
        }
    }
    &.danger {
        background: var(--ion-color-errorLight) !important;
        h6 {
            color: var(--ion-color-error) !important;
        }
    }
}

.alert-container {
    padding: 0.1rem;
    border: 1px solid;
    border-radius: 4px;
    &.warning {
        background: var(--ion-color-accent300) !important;
        border-color: var(--ion-color-accent800);
        color: var(--ion-color-accent800) !important;
    }
    &.info {
        background: var(--ion-color-neutro200) !important;
        border-color: var(--ion-color-neutro500);
        color: var(--ion-color-neutro500) !important;
    }
    &.danger {
        color: #721c24;
        background-color: #f8d7da;
        border-color: #f5c6cb;
    }
}
