.danger {
    color: var(--ion-color-danger);
}

.bold {
    font-weight: bold;
}

.available {
    color: var(--ion-color-warning);
}

.available-warning {
    color: var(--ion-color-danger);
}

.done {
    color: var(--ion-color-success);
}

.in_progress {
    color: var(--ion-color-warning);
}
.in-progress {
    color: var(--ion-color-warning);
}
.absent{
    color: var(--ion-color-gray);
}
.gone{
    color: var(--ion-color-gray);
}
.pending {
    color: var(--ion-color-lightGray);
}

.completed {
    color: var(--ion-color-success);
}

.font-1-5rem {
    font-size: 1.5rem;
}

.fw-500 {
    font-weight: 500;
}

.overflow-ellipsis {
    overflow: ellipsis;
}

.bg-absent {
    background-color: var(--ion-color-gray);
}
.bg-gone {
    background-color: var(--ion-color-gray);
}
.bg-in-progress {
    background-color: var(--ion-color-warning);
}
.bg-pending {
    background-color: var(--ion-color-lightGray);
}

.bg-completed {
    background-color: var(--ion-color-success);
}
