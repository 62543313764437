.mx-05{
    margin-left: .5rem;
    margin-right: .5rem;
}
.ml-05{
    margin-left: .5rem;
}
.mt-1{
    margin-top: 1rem;
}

.mt-1-i{
    margin-top: 1rem !important;
}

.mt-2{
    margin-top: 2rem;
}
.mt-05{
    margin-top: .5rem;
}

.h-50p {
    height: 50%;
}

.m-auto-0 {
    margin: auto 0 !important;
}

.mr-025{
    margin-right: .25rem;
}
.mr-05{
    margin-right: .5rem;
}
.mr-05-important{
    margin-right: .5rem !important;
}
.mr-1{
    margin-right: 1rem !important;
}
.m-start-4p{
    margin-inline-start: 4px !important;
}

.mt-035{
    margin-top: .35rem;
}
.mt-020{
    margin-top: .2rem !important;
}

.mb-020{
    margin-bottom: .2rem !important;
}
.mb-1{
  margin-bottom: 1rem !important;
}

.mb-2{
    margin-bottom: 2rem !important;
}

.mb-25{
    margin-bottom: 2.5rem !important;
}

.mb-4 {
    margin-bottom: 4rem !important;
}

.mb-5 {
    margin-bottom: 5rem !important;
}

.mb-18{
    margin-bottom: 1.8rem !important;
}

.mb-05{
    margin-bottom: 0.5rem !important;
}

.m-0{
    margin: 0;
}
.ml-auto{
    margin-left: auto;
}

.ml-2 {
    margin-left: 2rem;
}

.ml-18 {
    margin-left: 1.8rem;
}

.mr-auto{
    margin-right: auto;
}

.mr-2 {
    margin-right: 2rem;
}

.my-auto{
    margin-top: auto;
    margin-bottom: auto;
}
.mt-105em{
    margin-top: 1.5em;
}

.mt-0 {
    margin-top: 0;
}
