.self-center {
    align-self: center;
}
.center {
    text-align: center;
}
.text-right {
    text-align: right;
}
.align-center {
    vertical-align: middle;
    text-align: center;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -ms-flex-align: center;
    -webkit-align-items: center;
    -webkit-box-align: center;
    align-items: center;
}

.a-center {
    align-items: center;
}