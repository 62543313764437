.scroll {
    overflow: scroll;
}
.loading-container {
    width: 100%;
    display: flex;
    justify-content: center;
}
.disabled {
    pointer-events: none;
    opacity: 0.6;
}

.cursor-disabled {
    pointer-events: none;
}
.bold{
    font-weight: bold !important;
}

.w-100{
    width: 100%;
}
.h-100{
    height: 100%;
}

.d-flex{
    display: flex;
}
.notifyModal {
    --height: auto;
    --max-height: 80vh;
    --overflow: visible;

    & > .ion-page {
        overflow: visible;
    }
}