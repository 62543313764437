.p-1 {
    padding: 1rem;
}

.p-0 {
    padding: 0;
}

.px-05 {
    padding-left: 0.5rem;
    padding-right: 0.5rem;
}

.px-1 {
    padding-left: 1rem;
    padding-right: 1rem;
}

.pl-1 {
    padding-left: 1rem !important;
}
.pl-2{
    padding-left: 2rem;
}
.pl-3{
    padding-left: 3rem;
}

.pl-0 {
    padding-left: 0;
}

.pl-05 {
    padding-left: 0.5rem;
}

.pr-05 {
    padding-right: 0.5rem;
}

.pr-0 {
    padding-right: 0;
}

.pb-0 {
    padding-bottom: 0;
}

.pr-0 {
    padding-right: 0;
}

.pb-1 {
    padding-bottom: 1rem;
}