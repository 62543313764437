// Ionic Variables and Theming. For more info, please see:
// http://ionicframework.com/docs/theming/

/** Ionic CSS Variables **/
:root {
  /** primary **/
  --ion-color-primary: #6ba3bc;
  --ion-color-primary-rgb: 107, 163, 188;
  --ion-color-primary-contrast: #ffffff;
  --ion-color-primary-contrast-rgb: 255, 255, 255;
  --ion-color-primary-shade: #5a8ba1;
  --ion-color-primary-tint: #7cb4cd;

  /** lightPrimary **/
  --ion-color-lightPrimary: #b7def0;
  --ion-color-lightPrimary-note: #e1f6ff;
  --ion-color-lightPrimary-rgb: 183, 222, 240;
  --ion-color-lightPrimary-contrast: #000000;
  --ion-color-lightPrimary-contrast-rgb: 0, 0, 0;
  --ion-color-lightPrimary-shade: #a3c4d5;
  --ion-color-lightPrimary-tint: #cbe9f5;

  /** darkPrimary **/
  --ion-color-darkPrimary: #386e86;
  --ion-color-darkPrimary-rgb: 56, 110, 134;
  --ion-color-darkPrimary-contrast: #ffffff;
  --ion-color-darkPrimary-contrast-rgb: 255, 255, 255;
  --ion-color-darkPrimary-shade: #305d71;
  --ion-color-darkPrimary-tint: #497c99;

  /** secondary **/
  --ion-color-secondary: #e9eaed;
  --ion-color-secondary-rgb: 183, 222, 240;
  --ion-color-secondary-contrast: #000000;
  --ion-color-secondary-contrast-rgb: 0, 0, 0;
  --ion-color-secondary-shade: #a3c4d5;
  --ion-color-secondary-tint: #c3e3f2;

  /** success **/
  --ion-color-success: #10dc60;
  --ion-color-success-rgb: 16, 220, 96;
  --ion-color-success-contrast: #ffffff;
  --ion-color-success-contrast-rgb: 255, 255, 255;
  --ion-color-success-shade: #0ec254;
  --ion-color-success-tint: #28e070;

  /** warning **/
  --ion-color-warning: #ffad00;
  --ion-color-warning-rgb: 255, 206, 0;
  --ion-color-warning-contrast: #ffffff;
  --ion-color-warning-contrast-rgb: 255, 255, 255;
  --ion-color-warning-shade: #e0b500;
  --ion-color-warning-tint: #ffd31a;

  /** danger **/
  --ion-color-danger: #f53d3d;
  --ion-color-danger-rgb: 245, 61, 61;
  --ion-color-danger-contrast: #ffffff;
  --ion-color-danger-contrast-rgb: 255, 255, 255;
  --ion-color-danger-shade: #d33939;
  --ion-color-danger-tint: #f25454;

  /** dark **/
  --ion-color-dark: #222;
  --ion-color-dark-rgb: 34, 34, 34;
  --ion-color-dark-contrast: #ffffff;
  --ion-color-dark-contrast-rgb: 255, 255, 255;
  --ion-color-dark-shade: #1e2023;
  --ion-color-dark-tint: #383a3e;

  /** light **/
  --ion-color-light: #f4f4f4;
  --ion-color-light-rgb: 244, 244, 244;
  --ion-color-light-contrast: #000000;
  --ion-color-light-contrast-rgb: 0, 0, 0;
  --ion-color-light-shade: #d7d8da;
  --ion-color-light-tint: #f5f6f9;

  /** lightGray **/
  --ion-color-lightGray: #d3d3d3;
  --ion-color-lightGray-rgb: 211, 211, 211;
  --ion-color-lightGray-contrast: #000000;
  --ion-color-lightGray-contrast-rgb: 0, 0, 0;
  --ion-color-lightGray-shade: #bababa;
  --ion-color-lightGray-tint: #d7d7d7;

  /** extraLightGray **/
  --ion-color-extraLightGray: #f4f0ec;
  --ion-color-extraLightGray-rgb: 244, 240, 236;
  --ion-color-extraLightGray-contrast: #000000;
  --ion-color-extraLightGray-contrast-rgb: 0, 0, 0;
  --ion-color-extraLightGray-shade: #d7d3d0;
  --ion-color-extraLightGray-tint: #f5f2ee;

  /** customWhite **/
  --ion-color-customWhite: #ffffff;
  --ion-color-customWhite-rgb: 255, 255, 255;
  --ion-color-customWhite-contrast: #000000;
  --ion-color-customWhite-contrast-rgb: 0, 0, 0;
  --ion-color-customWhite-shade: #e0e0e0;
  --ion-color-customWhite-tint: #ffffff;

  /** gold **/
  --ion-color-gold: #ffd700;
  --ion-color-gold-rgb: 255, 215, 0;
  --ion-color-gold-contrast: #000000;
  --ion-color-gold-contrast-rgb: 0, 0, 0;
  --ion-color-gold-shade: #e0bd00;
  --ion-color-gold-tint: #ffdb1a;

  /** green **/
  --ion-color-green: #008000;
  --ion-color-green-rgb: 0, 128, 0;
  --ion-color-green-contrast: #ffffff;
  --ion-color-green-contrast-rgb: 255, 255, 255;
  --ion-color-green-shade: #007100;
  --ion-color-green-tint: #1a8d1a;

  /** orange **/
  --ion-color-orange: #ffa500;
  --ion-color-orange-rgb: 255, 165, 0;
  --ion-color-orange-contrast: #ffffff;
  --ion-color-orange-contrast-rgb: 0, 0, 0;
  --ion-color-orange-shade: #e09100;
  --ion-color-orange-tint: #ffae1a;

  /** yellow **/
  --ion-color-yellow: #ffff00;
  --ion-color-yellow-rgb: 255, 255, 0;
  --ion-color-yellow-contrast: #000000;
  --ion-color-yellow-contrast-rgb: 0, 0, 0;
  --ion-color-yellow-shade: #e0e000;
  --ion-color-yellow-tint: #ffff1a;

  /** pink **/
  --ion-color-pink: #ffc0cb;
  --ion-color-pink-rgb: 255, 192, 203;
  --ion-color-pink-contrast: #000000;
  --ion-color-pink-contrast-rgb: 0, 0, 0;
  --ion-color-pink-shade: #e0a9b3;
  --ion-color-pink-tint: #ffc6d0;

  /** blue **/
  --ion-color-blue: #0000ff;
  --ion-color-blue-rgb: 0, 0, 255;
  --ion-color-blue-contrast: #ffffff;
  --ion-color-blue-contrast-rgb: 255, 255, 255;
  --ion-color-blue-shade: #0000e0;
  --ion-color-blue-tint: #1a1aff;

  /** gray **/
  --ion-color-gray: #808080;
  --ion-color-gray-rgb: 128, 128, 128;
  --ion-color-gray-contrast: #ffffff;
  --ion-color-gray-contrast-rgb: 0, 0, 0;
  --ion-color-gray-shade: #717171;
  --ion-color-gray-tint: #8d8d8d;

  /** purple **/
  --ion-color-purple: #800080;
  --ion-color-purple-rgb: 128, 0, 128;
  --ion-color-purple-contrast: #ffffff;
  --ion-color-purple-contrast-rgb: 255, 255, 255;
  --ion-color-purple-shade: #710071;
  --ion-color-purple-tint: #8d1a8d;

  /** violet **/
  --ion-color-violet: #ee82ee;
  --ion-color-violet-rgb: 238, 130, 238;
  --ion-color-violet-contrast: #000000;
  --ion-color-violet-contrast-rgb: 0, 0, 0;
  --ion-color-violet-shade: #d172d1;
  --ion-color-violet-tint: #f08ff0;

  /** turquoise **/
  --ion-color-turquoise: #40e0d0;
  --ion-color-turquoise-rgb: 64, 224, 208;
  --ion-color-turquoise-contrast: #000000;
  --ion-color-turquoise-contrast-rgb: 0, 0, 0;
  --ion-color-turquoise-shade: #38c5b7;
  --ion-color-turquoise-tint: #53e3d5;

  /** Accent colors **/
  --ion-color-accent300: #fff3c4;
  --ion-color-accent400: #fce588;
  --ion-color-accent500: #fadb5f;
  --ion-color-accent600: #f7c948;
  --ion-color-accent700: #f0b429;
  --ion-color-accent800: #856404;
}

//TODO: dark mode
// @media (prefers-color-scheme: dark) {
//   :root {
//     /* dark mode variables go here */
//   }
// }

.ion-color-lightGray {
  /** lightGray **/
  --ion-color-base: var(--ion-color-lightGray);
  --ion-color-base-rgb: var(--ion-color-lightGray-rgb);
  --ion-color-contrast: var(--ion-color-lightGray-contrast);
  --ion-color-contrast-rgb: var(--ion-color-lightGray-contrast-rgb);
  --ion-color-shade: var(--ion-color-lightGray-shade);
  --ion-color-tint: var(--ion-color-lightGray-tint);
}

.ion-color-extraLightGray {
  --ion-color-base: var(--ion-color-extraLightGray);
  --ion-color-base-rgb: var(--ion-color-extraLightGray-rgb);
  --ion-color-contrast: var(--ion-color-extraLightGray-contrast);
  --ion-color-contrast-rgb: var(--ion-color-extraLightGray-contrast-rgb);
  --ion-color-shade: var(--ion-color-extraLightGray-shade);
  --ion-color-tint: var(--ion-color-extraLightGray-tint);
}

.ion-color-lightPrimary {
  --ion-color-base: var(--ion-color-lightPrimary);
  --ion-color-base-rgb: var(--ion-color-lightPrimary-rgb);
  --ion-color-contrast: var(--ion-color-lightPrimary-contrast);
  --ion-color-contrast-rgb: var(--ion-color-lightPrimary-contrast-rgb);
  --ion-color-shade: var(--ion-color-lightPrimary-shade);
  --ion-color-tint: var(--ion-color-lightPrimary-tint);
}

.ion-color-customWhite {
  --ion-color-base: var(--ion-color-customWhite);
  --ion-color-base-rgb: var(--ion-color-customWhite-rgb);
  --ion-color-base-contrast: var(--ion-color-customWhite-contrast);
  --ion-color-base-contrast-rgb: var(--ion-color-customWhite-contrast-rgb);
  --ion-color-base-shade: var(--ion-color-customWhite-shade);
  --ion-color-base-tint: var(--ion-color-customWhite-tint);
}

.ion-color-green {
  --ion-color-base: var(--ion-color-green);
  --ion-color-base-rgb: var(--ion-color-green-rgb);
  --ion-color-contrast: var(--ion-color-green-contrast);
  --ion-color-contrast-rgb: var(--ion-color-green-contrast-rgb);
  --ion-color-shade: var(--ion-color-green-shade);
  --ion-color-tint: var(--ion-color-green-tint);
}

.ion-color-gold {
  --ion-color-base: var(--ion-color-gold);
  --ion-color-base-rgb: var(--ion-color-gold-rgb);
  --ion-color-contrast: var(--ion-color-gold-contrast);
  --ion-color-contrast-rgb: var(--ion-color-gold-contrast-rgb);
  --ion-color-shade: var(--ion-color-gold-shade);
  --ion-color-tint: var(--ion-color-gold-tint);
}

.ion-color-orange {
  --ion-color-base: var(--ion-color-orange);
  --ion-color-base-rgb: var(--ion-color-orange-rgb);
  --ion-color-contrast: var(--ion-color-orange-contrast);
  --ion-color-contrast-rgb: var(--ion-color-orange-contrast-rgb);
  --ion-color-shade: var(--ion-color-orange-shade);
  --ion-color-tint: var(--ion-color-orange-tint);
}

.ion-color-yellow {
  --ion-color-base: var(--ion-color-yellow);
  --ion-color-base-rgb: var(--ion-color-yellow-rgb);
  --ion-color-contrast: var(--ion-color-yellow-contrast);
  --ion-color-contrast-rgb: var(--ion-color-yellow-contrast-rgb);
  --ion-color-shade: var(--ion-color-yellow-shade);
  --ion-color-tint: var(--ion-color-yellow-tint);
}

.ion-color-pink {
  --ion-color-base: var(--ion-color-pink);
  --ion-color-base-rgb: var(--ion-color-pink-rgb);
  --ion-color-contrast: var(--ion-color-pink-contrast);
  --ion-color-contrast-rgb: var(--ion-color-pink-contrast-rgb);
  --ion-color-shade: var(--ion-color-pink-shade);
  --ion-color-tint: var(--ion-color-pink-tint);
}

.ion-color-blue {
  --ion-color-base: var(--ion-color-blue);
  --ion-color-base-rgb: var(--ion-color-blue-rgb);
  --ion-color-contrast: var(--ion-color-blue-contrast);
  --ion-color-contrast-rgb: var(--ion-color-blue-contrast-rgb);
  --ion-color-shade: var(--ion-color-blue-shade);
  --ion-color-tint: var(--ion-color-blue-tint);
}

.ion-color-gray {
  --ion-color-base: var(--ion-color-gray);
  --ion-color-base-rgb: var(--ion-color-gray-rgb);
  --ion-color-contrast: var(--ion-color-gray-contrast);
  --ion-color-contrast-rgb: var(--ion-color-gray-contrast-rgb);
  --ion-color-shade: var(--ion-color-gray-shade);
  --ion-color-tint: var(--ion-color-gray-tint);
}

.ion-color-purple {
  --ion-color-base: var(--ion-color-purple);
  --ion-color-base-rgb: var(--ion-color-purple-rgb);
  --ion-color-contrast: var(--ion-color-purple-contrast);
  --ion-color-contrast-rgb: var(--ion-color-purple-contrast-rgb);
  --ion-color-shade: var(--ion-color-purple-shade);
  --ion-color-tint: var(--ion-color-purple-tint);
}

.ion-color-violet {
  --ion-color-base: var(--ion-color-violet);
  --ion-color-base-rgb: var(--ion-color-violet-rgb);
  --ion-color-contrast: var(--ion-color-violet-contrast);
  --ion-color-contrast-rgb: var(--ion-color-violet-contrast-rgb);
  --ion-color-shade: var(--ion-color-violet-shade);
  --ion-color-tint: var(--ion-color-violet-tint);
}

.ion-color-turquoise {
  --ion-color-base: var(--ion-color-turquoise);
  --ion-color-base-rgb: var(--ion-color-turquoise-rgb);
  --ion-color-contrast: var(--ion-color-turquoise-contrast);
  --ion-color-contrast-rgb: var(--ion-color-turquoise-contrast-rgb);
  --ion-color-shade: var(--ion-color-turquoise-shade);
  --ion-color-tint: var(--ion-color-turquoise-tint);
}
