ion-progress-bar {
    height: 20px;
    border-radius: 15px;
    margin-right: 1rem;
}

.progress-text {
    position: relative;
    bottom: 20px;
    text-align: center;
    width: 100%;
    color: white;
    font-weight: bold;
    margin: 0;
}

ion-toolbar {
    &.chat-toolbar {
        border-top: 1px solid var(--ion-color-lightGray);
        &.padding-bottom-ios {
            // al treure ion-footer hem d'afegir aquest padding per ios
            padding-bottom: 25px;
        }
    }
}

.p-relative {
    position: relative;
}