@media screen and (min-width:768px) {
    .show-in-mobile {
        display: none !important;
    }
}

@media screen and (max-width: 767px){
    .hide-in-mobile {
        display: none !important;
    }
}